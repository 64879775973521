<template>
  <div>
    <Top title="提交结果" />
    <div class="resut bg-block mt54">
      <a-result
        status="success"
        title="提交成功"
        sub-title="问题已通知相关人员，请注意微信提示"
      >
        <template #extra>
          <a-button key="console" @click="toHome"> 回到首页 </a-button>
          <a-button
            key="buy"
            type="primary"
            @click="$router.push(`/detail?id=${id}`)"
          >
            查看事项
          </a-button>
        </template>
      </a-result>
    </div>
  </div>
</template>
<script>
import { Result, Button } from "ant-design-vue";
import Top from "@/components/top/top";
export default {
  components: {
    AResult: Result,
    AButton: Button,
    Top,
  },
  data() {
    return {
      id: this.$route.query.id,
    };
  },
  methods: {
    toHome() {
      this.$router.push("/");
    },
  },
};
</script>